.home-img {
  height: 85.5vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  // background-attachment: fixed;
  transition: 0.6s;
  background-image: url("./images/rk_drive.jpg");
}

.home-img1 {
  height: 85.5vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  // background-attachment: fixed;
  transition: 0.6s;
  background-image: url("./images/home-img2.jpeg");
}

@media screen and (max-width: 768px) {
  .home-img {
    height: 60vh;
    background-image: url("./images/rk_drive.jpg");

    background-attachment: initial;
  }
  .home-img1 {
    height: 45.5vh;
    width: 100%;
  }
}
