.imgs1 {
  max-height: 80px;
  width: auto;
  background-size: cover;
  transition: max-height 0.3s ease;
}

.imgs2 {
  max-height: 90px;
  width: auto;
  background-size: cover;
  transition: max-height 0.3s ease;
}

.head1 {
  background-color: white !important;

  max-height: 200px;
  color: #000 !important;
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.head2 {
  background-color: transparent !important;

  max-height: 200px;
  transition: max-height 0.5s ease;
  transition: background-color 0.5s ease-out;
}
.head3 {
  background-color: white;

  max-height: 200px;
  transition: max-height 0.3s ease;
  transition: background-color 0.5s ease-out;
}

.mainHead {
  position: fixed;
  max-width: 1920px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 19;
  margin: auto;
}
@media screen and (max-width: 1064px) {
  .head2 {
    background-color: #fff !important;
  }
}
