.swiper {
  width: 360px;
  height: 360px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
  overflow: hidden !important;
}

@media only screen and (max-width: 800px) {
  .swiper {
    width: 300px;
    height: 300px;
  }
}
