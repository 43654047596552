.greeting-head2 {
  background-color: transparent;

  max-height: 130px;
}

.mainHead1 {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  z-index: 19;
  margin: auto;
}

.greeting-imgs2 {
  max-height: 72px;
  width: auto;
  background-size: cover;
  transition: max-height 0.3s ease;
}
.resp-margin {
  height: 140px;
}

@media screen and (max-width: 1064px) {
  .resp-margin {
    height: 66px;
  }
}
@media screen and (max-width: 500px) {
  .resp-margin {
    height: 65px;
  }
}
